import React, { useState } from 'react'
import {ProfileDetails} from './cards/ProfileDetails'

export function Settings() {
  
  return (
    <>
      <ProfileDetails />
    </>
  )
}
