import React, { useEffect, useState } from "react";
import {
  IProfileDetails,
  QuestionSetDetails,
  profileDetailsInitValues as initialValues
} from "../SettingsModel";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { fetchQuestionsSet, saveScreeningTest } from "../../../../../api";
import { useNavigate } from "react-router-dom";

const profileDetailsSchema = Yup.object().shape({
  testName: Yup.string().required("Test Name is required"),
  testDescription: Yup.string().required("Test Description is required"),
  questionSetId: Yup.string().required("Question Set Id is required"),
  totalMarks: Yup.string().required("Total Marks is required"),
  duration: Yup.string().required("Duration is required"),
});

const ProfileDetails: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues);
  const [queSet, setQueSet] = useState<QuestionSetDetails[]>([])
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate);
    setData(updatedData);
  };

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const updatedData = Object.assign(data, values);
      console.log(updatedData);
      const result = await saveScreeningTest(updatedData)
      if (result.data) {
        alert("Test Successfully Created")
        setResult(result.data);
        navigate('/screeningtest/view')
      }
      setLoading(false)
    }
  });

  useEffect(() => {
    fetchQuestionsSet({
      "id": "",
      "category": "",
      "set_name": "",
      "level": "",
      "tags": ""
    }).then((res) => {

      var tempArr: QuestionSetDetails[] = []
      res.data.map((qset: { [key: string]: string | number }) => {
        var tempdata: QuestionSetDetails = {
          id: String(qset._id),
          setname: String(qset.set_name),
          questionCount: Number(qset.tot_question)
        }
        tempArr.push(tempdata)
        console.log(tempdata)
      })
      setQueSet(tempArr)
      console.log(tempArr)
    })
  }, [])

  return (
    <>
      <div className="card mb-5 mb-xl-10">
       
      </div>
    </>
  );
};

export { ProfileDetails };
