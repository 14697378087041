import React from 'react'
// import { TablesWidget11 } from '../../../_metronic/partials/widgets'
import { TablesWidget11 } from "../../modules/candidate/components/TablesWidget11"
import { CandidateHeader } from "./CandidateHeader"
// import { Header } from '../../../_metronic/layout/components/header/Header'
type Props = {}

export default function CandidateResultPage({ }: Props) {
    return (
        <div>
            <div>
                <CandidateHeader/>
            </div>
            <TablesWidget11 className='card-xxl-stretch mb-5 mb-xl-10' title="" />
        </div>
    )
}