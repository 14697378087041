import React, { useEffect, useState } from 'react'
import { Blocks } from "react-loader-spinner";
import { Link } from 'react-router-dom'

import { StatisticsWidget1, StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { filterScheduledTestByEmail } from '../../api'
import { useAuth } from '../../modules/auth'

type Props = {}

type Tests = {
  "candidate_email": string,
  "datetime": string,
  "expiry": string,
  "question_set_name": string,
  "test_name": string,
  "description": string,
  "duration": string,
  "total_marks": string,
  "testId": string,
  "status": string,
  "result": string
}

export default function CandidateDashboard({ }: Props) {
  const { currentUser } = useAuth()
  const [tests, setTests] = useState<Tests[]>([])

  useEffect(() => {
    filterScheduledTestByEmail({ "candidate_email": currentUser?.email }).then((res) => {
      setTests(res.data)
    })
  }, [])
  
  return (
    <div>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {tests.length > 0 ?
          <>
            {tests.map((test) => (
              <div className='col-xl-4'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='document'
                  color='light'
                  iconColor='white'
                  title={test.test_name}
                  titleColor='dark'
                  description={test.description}
                  descriptionColor='dark'
                  test_name={test.test_name}
                  datetime={test.datetime}
                  status={test.status}
                  expiry={test.expiry}
                  testId={test.testId}
                  result={test.result}
                />
              </div>
            ))}
          </> :
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        }

      </div>
      {/* end::Row */}
    </div>
  )
}