import React, { useEffect, useState } from "react";
import { read, utils, writeFile } from "xlsx";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  cronjob_data,
  deleteFiles,
  filesByUser,
  getClientFileList,
  processFile,
  processMultipleFile,
  sqlDataCreate
} from "../../api";
import { Blocks } from "react-loader-spinner";
import { AddDatasetForm } from "../../modules/apps/user-management/users-list/user-edit-modal/AddDatasetForm";
import { useAuth } from "../../modules/auth";
import { generateSasUrl } from "../../modules/azure/generateSasUrl";
import { BlobServiceClient } from "@azure/storage-blob";
import { Document, Page } from "react-pdf";
import axios from "axios";
import XLSX from "xlsx"; // or use a React-specific Excel rendering library
import ExcelRenderer from "react-excel-renderer";
// import { saveAs } from 'file-saver';

type Props = {};

type fileType = Array<string>;

const bgList = ["bg-success", "bg-primary", "bg-warning"];

type MyObject = {
  [key: string]: string[];
};

type ClientFileObject = {
  fileName: string;
  fileSize: string;
  fileUrl: string;
  createdAt: string;
  tag: string;
  processed: number;
};

type CronJobReqObject = {
  email: string;
  username: string;
  scheduledTime: string;
  scheduleFreq: string;
  year?: number | null;
  month?: number | null;
  day?: number | null;
  dayOfWeek?: number | null;
  hours?: number | null;
  minutes?: number | null;
  seconds?: number | null;
  milliseconds?: number | null;
};

export default function SinghiDocumentProcessing({ }: Props) {
  const { currentUser } = useAuth();

  const [excelData, setExcelData] = useState<string[][] | null>(null);
  const [clientFiles, setClientFiles] = useState<Array<ClientFileObject>>([]);
  const [fileType, setFileType] = useState<string>("Source");
  const [isFetching, setIsFetching] = useState(false);
  const [sasUrl, setSasUrl] = React.useState("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [scheduleTime, setScheduleTime] = useState<string>();
  const [scheduleFreq, setScheduleFreq] = useState<string>("");
  const [previousCronJob, setPreviousCronJob] = useState();
  const [currentFileName, setCurrentFileName] = useState<string>();

  const handleProcess = () => {
    // api call
    setProcessing(true);
    processMultipleFile({
      userName: currentUser?.username,
      email: currentUser?.email
    }).then((res) => {
      console.log(res);
      getClientFileList({
        userEmail: currentUser?.email,
        folderName: "Destination"
      })
        .then((res) => {
          console.log(res);
          setFileType("Destination");
          setClientFiles(res.data);
          setProcessing(false);
        })
        .catch((e) => {
          setProcessing(false);
          alert("Facing Some Error. Please Try Again");
        });
    });
  };

  function padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  const handleScheduling = () => {
    // Create a Date object for a specific date and time (year, month, day, hours, minutes, seconds, milliseconds)
    if (currentUser?.email && currentUser?.username) {
      if (scheduleTime) {
        const specificDate: Date = new Date(scheduleTime);
        // Get the year, month, day, hours, minutes, seconds, and milliseconds from the Date object
        const year: number = specificDate.getUTCFullYear();
        const month: number = specificDate.getUTCMonth() + 1;
        const day: number = specificDate.getUTCDate();
        const dayOfWeek: number = specificDate.getUTCDay();
        const hours: number = specificDate.getUTCHours();
        const minutes: number = specificDate.getUTCMinutes();
        const seconds: number = specificDate.getUTCSeconds();
        const milliseconds: number = specificDate.getUTCMilliseconds();
        const utcTime = `${year}-${padZero(month)}-${padZero(day)} ${padZero(
          hours
        )}:${padZero(minutes)}:${padZero(seconds)}:${padZero(milliseconds)}`;

        // const seconds: number = specificDate.getSeconds();
        // const milliseconds: number = specificDate.getMilliseconds();
        let cronjobReqObj: CronJobReqObject = {
          username: currentUser.username,
          email: currentUser.email,
          scheduledTime: utcTime,
          scheduleFreq: scheduleFreq
        };
        if (scheduleFreq == "") {
          cronjobReqObj["hours"] = hours;
          cronjobReqObj["minutes"] = minutes;
          cronjobReqObj["day"] = day;
          cronjobReqObj["month"] = month;
        } else if (scheduleFreq == "Daily") {
          cronjobReqObj["hours"] = hours;
          cronjobReqObj["minutes"] = minutes;
        } else if (scheduleFreq == "Weekly") {
          cronjobReqObj["hours"] = hours;
          cronjobReqObj["minutes"] = minutes;
          cronjobReqObj["dayOfWeek"] = dayOfWeek;
        } else if (scheduleFreq == "Monthly") {
          cronjobReqObj["hours"] = hours;
          cronjobReqObj["minutes"] = minutes;
          cronjobReqObj["day"] = day;
        } else {
          alert("Please Select Valid Repetation Frequency");
        }
        cronjob_data(cronjobReqObj)
          .then((res) => {
            alert(`Cron Job Created for ${hours}:${minutes}`);
          })
          .catch((e) => {
            alert("Error Creating CronJob");
          });
      } else {
        alert("Please Select Date and Time");
      }
    } else {
      alert("Error Occured. Please Try Again");
    }
  };

  const fetchClientFile = () => {
    setClientFiles([]);
    setIsFetching(true);
    getClientFileList({
      userEmail: currentUser?.email,
      folderName: fileType
    })
      .then((res) => {
        console.log(res);
        setIsFetching(false);
        setClientFiles(res.data);
      })
      .catch((e) => {
        setIsFetching(false);
      });
  };

  const handlePreview = async (filename: string) => {
    setCurrentFileName(filename);
    // try {
    //   const response = await axios.get(
    //     `${toAbsoluteUrl("/media/hdfcFile/BankReport.xlsx")}`,
    //     {
    //       responseType: "blob"
    //     }
    //   );

    //   const blob = new Blob([response.data], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    //   });

    //   if (blob) {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       const data = e.target!.result as ArrayBuffer; // Use a type assertion here
    //       const workbook = XLSX.read(data, { type: 'binary' });
    //       const sheetName = workbook.SheetNames[0];
    //       const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

    //       // Now you have the 'excelData' as an array of objects containing the rows from the Excel file
    //       console.log(excelData);
    //     };
    //     reader.readAsArrayBuffer(blob);
    //   }
    // } catch (error) {
    //   console.error("Error fetching Excel file:", error);
    // }
  };

  const downloadExcel = async () => {
    try {
      const response = await axios.get(
        `${toAbsoluteUrl(
          `/media/singhi/${currentUser?.username}/${currentFileName}`
        )}`,
        {
          responseType: "blob" // This ensures that the response is treated as a binary blob
        }
      );

      console.log(response)
      if (response.data.type == 'text/html') {
        alert("No files present to be downloded")
      } else {
        // Create a Blob object from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = currentFileName ? currentFileName : "excelFile.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  useEffect(() => {
    setIsFetching(true);
    getClientFileList({
      userEmail: currentUser?.email,
      folderName: "Source"
    })
      .then((res) => {
        console.log(res);
        setClientFiles(res.data);
        setIsFetching(false);
      })
      .catch((e) => { });
  }, []);

  useEffect(() => {
    deleteFiles({ username: currentUser?.username, useremail: currentUser?.email }).then((res) => { console.log(res) })
  }, [])

  return (
    <div>
      {/* Upload Document */}
      <div
        className="modal fade"
        id="kt_modal_assosiate_student"
        aria-hidden="true"
      >
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Upload Document</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <AddDatasetForm />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Settings Preview */}
      <div className="modal fade" id="kt_modal_settings" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-650px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Settings</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <p>
                  <strong>Azure Source Blob : </strong>{" "}
                  <i>{currentUser?.username}/Source</i> Folder
                </p>
                <p>
                  <strong>Azure Destination Blob : </strong>{" "}
                  <i>{currentUser?.username}/Destination</i> Folder
                </p>

                <hr />
                <p>
                  <strong>Client Username : </strong> {currentUser?.username}
                </p>
                <p>
                  <strong>Client Email : </strong> {currentUser?.email}
                </p>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Scheduling Preview */}
      <div className="modal fade" id="kt_modal_schedule" aria-hidden="true">
        {/* begin::Modal dialog */}
        <div className="modal-dialog modal-dialog-centered mw-450px">
          {/* begin::Modal content */}
          <div className="modal-content">
            <div className="modal-header">
              {/* begin::Modal title */}
              <h2 className="fw-bolder">Schedule Processing</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <form>
                  <label>Repeat</label>
                  <select
                    value={scheduleFreq}
                    onChange={(e) => setScheduleFreq(e.target.value)}
                    className="form-control"
                  >
                    <option value="">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                  <br />
                  <label htmlFor="datetime">Select Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datetime"
                    name="datetime"
                    className="form-control"
                    onChange={(e) => {
                      setScheduleTime(e.target.value);
                    }}
                  />
                </form>
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-100 fs-5"
                onClick={handleScheduling}
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="timer" className="fs-3" />
                Confirm Schedule
              </button>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>

      {/* Document Preview */}
      <div className="modal fade" id="kt_modal_preview" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Preview Document</h2>

              {/* begin::Close */}
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              {/* <Document file={pdfUrl}>
                <Page pageNumber={1} />
              </Document> */}
              <div>
                {/* <iframe
                  src={sasUrl}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe> */}
                <button onClick={downloadExcel}>Download Excel</button>
                {excelData && (
                  <table>
                    <thead>
                      <tr>
                        {excelData[0].map((cell, index) => (
                          <th key={index}>{cell}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {excelData.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-xl-stretch mb-xl-8 bg-light">
        {/* begin::Header */}
        <div className="card-header border-0 pt-5 mb-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark mb-2">
              {" "}
              Document Library{" "}
            </span>
            {/* <span className="text-muted mt-1 fw-semibold fs-7">
              Search your <strong> files</strong>
            </span> */}
          </h3>
        </div>
        {/* end::Header */}

        {/* begin: Btn Row */}
        {/* <div className="row">
          <div
            className="d-flex justify-content-between my-1"
            data-kt-user-table-toolbar="base"
          >
            <div className="px-5">
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_settings"
              >
                <KTIcon iconName="gear" className="fs-1" />
                Settings
              </button>
              <button
                type="button"
                className="btn btn-light-primary me-3 w-200px"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <i className="ki-duotone ki-filter fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                Filter
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                data-kt-menu="true"
              >
                <div className="px-7 py-5">
                  <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                </div>
                <div className="separator border-gray-200"></div>
                <div className="px-7 py-5" data-kt-user-table-filter="form">
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Type:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      value={fileType}
                      onChange={(e) => {
                        setFileType(e.target.value);
                      }}
                    >
                      <option value="">-- Select File Type --</option>
                      <option value="Archive">Archived</option>
                      <option value="Destination">Processed</option>
                      <option value="Source">Unprocessed</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="reset"
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn btn-success fw-bold px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="filter"
                      onClick={fetchClientFile}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-180px "
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_assosiate_student"
              >
                <KTIcon iconName="plus" className="fs-3" />
                Upload Document
              </button>

              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-primary me-3 my-3 w-250px"
                onClick={handleProcess}
              >
                {!processing && (
                  <span className="indicator-label d-flex align-items-center justify-content-center">
                    <KTIcon iconName="abstract-8" className="fs-1" />
                    Start Processing
                  </span>
                )}
                {processing && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Processing...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>

              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-250px"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_schedule"
              >
                <KTIcon iconName="time" className="fs-1" />
                Schedule Processing
              </button>
            </div>
          </div>
        </div> */}
        {/* end :: Btn Row */}

        {clientFiles.length === 0 ? (
          <div className="row">
            {isFetching ? (
              <Blocks
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
              />
            ) : (
              <div className="card-header border-0 d-flex justify-content-between my-1">
                {/* {folderType !== "" && ( */}
                <h3 className="card-title fw-bold text-dark">
                  <KTIcon iconName="folder" className="fs-2x text-primary" />
                  &nbsp; No Files Found in {fileType} Folder
                </h3>
                {/* )} */}
              </div>
            )}
          </div>
        ) : (
          <div className="card-body pt-5">
            <div className="row d-flex justify-content-between position-relative">
              {/* <ListsWidget3 className='card-xl-stretch mb-5 mb-xl-8 px-5' folderName='Hal Document' files={[...files]} /> */}

              {/* Folder UI */}
              <div className="col-12">
                <div
                  className={`card card-xl-stretch mb-5 mb-xl-8`}
                  style={{ maxHeight: "70vh", overflowY: "scroll" }}
                >
                  {clientFiles.length > 0 && (
                    <>
                      {/* begin::Header */}
                      <div className="card-header border-0">
                        <h3 className="card-title fw-bold text-dark">
                          <KTIcon
                            iconName="folder"
                            className="fs-2x text-primary"
                          />
                          &nbsp;
                          {/* {folder.folderName} */}
                          {currentUser?.username} {fileType} Folder
                        </h3>
                      </div>
                      {/* end::Header */}
                      {/* begin::Body */}
                      <div className="card-body pt-2">
                        <table
                          id="kt_table_users"
                          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-center"
                          role="table"
                        >
                          <thead>
                            <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0">
                              {/* <th
                        role="columnheader"
                        className="min-w-125px"
                        style={{ cursor: "pointer" }}
                      >
                        Licence Number
                      </th> */}
                              <th
                                role="columnheader"
                                className="min-w-125px text-center"
                                style={{ cursor: "pointer" }}
                              >
                                File Name
                              </th>
                              <th
                                role="columnheader"
                                className="min-w-125px text-center"
                                style={{ cursor: "pointer" }}
                              >
                                Created At
                              </th>
                              <th
                                role="columnheader"
                                className="min-w-125px text-center"
                                style={{ cursor: "pointer" }}
                              >
                                File Size
                              </th>
                              <th
                                role="columnheader"
                                className="min-w-125px text-center"
                                style={{ cursor: "pointer" }}
                              >
                                Bank Type
                              </th>
                              <th
                                role="columnheader"
                                className="min-w-125px text-center"
                                style={{ cursor: "pointer" }}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="text-gray-600 fw-bold"
                            role="rowgroup"
                          >
                            {clientFiles.map((file, index) => (
                              <tr role="row">
                                {/* <td role="cell" className="">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <a href="#"></a>
                              </div>
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="text-gray-800 text-hover-primary mb-1"
                                >
                                  {item.licenceNumber}
                                </a>
                              </div>
                            </div>
                          </td> */}
                                <td role="cell" className="">
                                  <a
                                    href={file.fileUrl}
                                    target="_blank"
                                    onClick={() => handlePreview(file.fileName)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_preview"
                                  >
                                    {file.fileName}
                                  </a>
                                </td>
                                <td role="cell" className="">
                                  <div className="text-gray-800 text-hover-primary mb-1">
                                    {file.createdAt}
                                  </div>
                                </td>
                                <td role="cell" className="">
                                  <div className="text-gray-800 text-hover-primary mb-1">
                                    {Number(file.fileSize).toFixed(2)} Kb
                                  </div>
                                </td>
                                <td role="cell" className="">
                                  <div className="text-gray-800 text-hover-primary mb-1">
                                    {file.tag}
                                  </div>
                                </td>
                                <td role="cell" className="">
                                  <div
                                    className={
                                      "badge fw-bolder " +
                                      (file.processed == 1
                                        ? "badge-success"
                                        : "badge-danger")
                                    }
                                  >
                                    {file.processed == 0
                                      ? "Unprocessed"
                                      : "processed"}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* end::Body */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
