/* eslint-disable jsx-a11y/anchor-is-valid */
import { TablesWidget11 } from "./TablesWidget11";

export function Overview() {
  return (
    <>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <TablesWidget11 className='card-xxl-stretch mb-5 mb-xl-10' title="Candidate List" />
        </div>
      </div>
    </>
  );
}
