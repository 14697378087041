/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { Link } from "react-router-dom";

type Props = {
  className: string;
};

const ListsWidget1: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">Subscribed Models</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Test to see the Magic
          </span>
        </h3> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-5">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Link to={"/questionset/view"}>
              <div className="d-flex align-items-center mb-7">

                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-success">
                    <KTIcon
                      iconName="abstract-26"
                      className="fs-3x text-success"
                    />
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark text-hover-primary fs-2 fw-bold"
                  >
                    Question Set
                  </a>
                  {/* <span className="text-muted fw-semibold">
                    Project Manager
                  </span> */}
                </div>

              </div>
            </Link>

            <Link to={"/scheduletest/view"}>
              <div className="d-flex align-items-center mb-7">

                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-warning">
                    <KTIcon iconName="pencil" className="fs-3x text-warning" />
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark text-hover-primary fs-2 fw-bold"
                  >
                    Schedule Test
                  </a>
                  {/* <span className="text-muted fw-semibold">Art Director</span> */}
                </div>

              </div>
            </Link>
            <Link to={"/resumescheduling"}>
              <div className="d-flex align-items-center mb-7">

                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-info">
                    <KTIcon
                      iconName="user"
                      className="fs-3x text-info"
                    />
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark text-hover-primary fs-2 fw-bold"
                  >
                    Resume Test Scheduling
                  </a>
                  {/* <span className="text-muted fw-semibold">QA Managers</span> */}
                </div>

              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-lg-6">
            <Link to={"/screeningtest/view"}>
              <div className="d-flex align-items-center mb-7">

                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-danger">
                    <KTIcon
                      iconName="disconnect"
                      className="fs-3x text-danger"
                    />
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark text-hover-primary fs-2 fw-bold"
                  >
                    Create New Test
                  </a>
                  {/* <span className="text-muted fw-semibold">DevOps</span> */}
                </div>

              </div>
            </Link>

            <Link to={"/candidateresult"}>
              <div className="d-flex align-items-center mb-7">

                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-light-info">
                    <KTIcon
                      iconName="security-user"
                      className="fs-3x text-info"
                    />
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="text-dark text-hover-primary fs-2 fw-bold"
                  >
                    View Candidates Result
                  </a>
                  {/* <span className="text-muted fw-semibold">QA Managers</span> */}
                </div>

              </div>
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

export { ListsWidget1 };
