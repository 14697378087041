/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from "react";
import {
  DropzoneRootProps,
  DropzoneInputProps,
  useDropzone,
  FileRejection,
  DropEvent
} from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../auth";
import {
  downloadGdriveFile,
  fetchScreeningTest,
  getClientListSinghi,
  login,
  saveSchedulingTestCSV,
  uploadFile
} from "../../../../../api";
import { KTIcon } from "../../../../../../_metronic/helpers";
import useDrivePicker from "react-google-drive-picker";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { google } from "googleapis";
import { GoogleLogin } from "react-google-login";
import axios, { Method } from "axios";

type ClientObject = {
  clientName: string;
  email: string;
};

export function AddDatasetForm() {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [datetime, setDatetime] = useState<string>("");
  const [testId, setTestId] = useState<string>("");
  const [pdfFile, setPdfFile] = useState<File>();
  const [fileName, setFileName] = useState<Array<string>>([]);
  const [driveFiles, setDriveFiles] = useState<Array<string>>([]);
  const [useremail, setUseremail] = useState<string>("");
  const [client, setClient] = useState<Array<ClientObject>>([]);
  const [openPicker, data] = useDrivePicker();
  const [testList, setTestList] = useState<{ id: string, testname: string }[]>([])

  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const loginToken = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/drive.readonly",
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      let payloadObj = {
        token: tokenResponse?.access_token,
        userName: currentUser?.username,
        userEmail: currentUser?.email
      };
      openPicker({
        clientId:
          "365521252893-ro7sdp4htqo8p1h7desmtesvleq777ve.apps.googleusercontent.com",
        developerKey: "AIzaSyDqDaxSUF2Cm1e9pIwDCGfYkzq1sS6fde0",
        viewId: "DOCS", // Set viewId to 'FOLDERS' to enable folder selection
        // viewMimeTypes: "application/vnd.google-apps.folder,application/json",
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        token: tokenResponse?.access_token,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          if (data.action === "cancel") {
            console.log("User clicked cancel/close button");
          }
          console.log(data);
          if (data.docs.length == 1 && data.docs[0].type === "folder") {
            const folderId = data.docs[0].id;
            const type = data.docs[0].type;
            console.log("Selected folder ID:", folderId);
            downloadGdriveFile({
              ...payloadObj,
              docId: folderId,
              type: type
            }).then((res) => {
              console.log(res);
              window.location.reload();
            });
          }
          if (data.docs.length > 1) {
            let tempDriveFiles: string[] = [];
            data.docs.map((doc) => {
              // Add other file IDs to the tempDriveFiles array if needed
              tempDriveFiles.push(doc.id);
            });
            console.log(tempDriveFiles);
            downloadGdriveFile({
              ...payloadObj,
              docId: tempDriveFiles,
              type: "file"
            }).then((res) => {
              console.log(res);
            });
            setDriveFiles([...tempDriveFiles]);
          }
        }
      });
    }
  });

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Handle file changes here
      console.log(acceptedFiles);
      if (acceptedFiles) {
        const fileList = Array.from(acceptedFiles);
        acceptedFiles.map((file) => {
          let tempFileNames = fileName;
          tempFileNames.push(file.name);
          setFileName([...tempFileNames]);
        });
        let seletedFile = acceptedFiles[0];
        setPdfFile(seletedFile);
        setSelectedFiles(fileList);
      }
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    let enableProcess = true;

    if (selectedFiles.length == 1) {
      const formData = new FormData();
      let fileArr = [];
      selectedFiles.forEach((file, index) => {
        formData.append("csv_file", file, `${file.name}`);
      });
      if (testId != "") {
        formData.append("testId", testId);
      } else {
        enableProcess = false;
        alert("Please select a Test");
        setLoading(false);
      }
      if (datetime != "") {
        formData.append("datetime", datetime);
      } else {
        enableProcess = false;
        alert("Please select a date");
        setLoading(false);
      }

      // Example using fetch API:
      if (enableProcess) {
        saveSchedulingTestCSV(formData)
          .then((response) => {
            setLoading(false);
            console.log(response);
            alert("Test Successfully Scheduled")
            navigate('/scheduletest/view')
          })
          .then((data) => { })
          .catch((error) => {
            // Handle error
          });
      }
    } else {
      setLoading(false);
      alert("You should upload one file");
    }
  };

  useEffect(() => {
    fetchScreeningTest().then((res) => {

      var tempArr: { id: string, testname: string }[] = []
      res.data.map((test: { [key: string]: string }) => {
        var tempdata: { id: string, testname: string } = {
          id: test._id,
          testname: test.test_name
        }
        tempArr.push(tempdata)
      })
      setTestList(tempArr)
      console.log(tempArr)
    })
  }, [])

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
    >
      <div className="mb-10">
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            <span className="required">Datetime</span>
          </label>

          <div className="col-lg-8 fv-row">
            <input
              type="datetime-local"
              className="form-control form-control-lg form-control-solid"
              placeholder="Enter date and time"
              value={datetime}
              onChange={(e) => setDatetime(e.target.value)}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            <span className="required">Test Id </span>
          </label>

          <div className="col-lg-8 fv-row">
            <select
              className="form-select form-select-solid form-select-lg fw-bold"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              value={testId}
              onChange={(e) => setTestId(e.target.value)}
            >
              <option value="">Select a Test...</option>
              {testList.map((test, i) => (
                <option value={test.id} key={i}>
                  {test.testname}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* </>
        )} */}
      </div>

      <h4 className='text-gray-800 fw-bolder'>Upload the CSV in the specified format</h4>

      <div
        {...getRootProps()}
        style={{
          border: "2px dashed",
          padding: "80px 40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1b1b29",
          marginBottom: "20px"
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            {fileName.length <= 0 ? (
              <>Drag media here to upload or connect an account...</>
            ) : (
              <div className="d-flex flex-column">
                {fileName.map((file: string) => (
                  <div className="badge badge-primary m-1">
                    <KTIcon iconName="file" className="fs-3" /> &nbsp; {file}
                  </div>
                ))}
              </div>
            )}
          </p>
        )}
      </div>

      {/* begin::Form group */}

      <div className="text-center mt-5">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-success w-100 mb-5"
          onClick={handleSubmit}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  );
}
