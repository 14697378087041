/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
// import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { KTIcon } from '../../../../_metronic/helpers';
import { fetch_all_candidate, scheduleMeeting } from "../../../api";

type Props = {
  className: string
  title: string
}

const TablesWidget11: React.FC<Props> = ({ className, title }) => {

  const [studentdata, setStudentdata] = useState<{ name: string, college: string, email: string, phone_number: string, secondary: string, higersecondary: string, sem_avg: string, skill_set: string, result: string }[]>([])
  const [meetingData, setMeetingData] = useState<{
    candidate_email?: string,
    intervier_email?: string,
    start_date_time?: string,
    duration?: string
  }>({})

  function formatDateWithOffset(dateString: any, offset: any) {
    // Create a Date object from the input date string
    const date = new Date(dateString);

    // Function to pad numbers with leading zeroes
    const pad = (num: number) => num.toString().padStart(2, '0');

    // Get the components
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Format as ISO 8601 with the provided offset
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offset}`;

    return formattedDate;
  }

  const handleScheduling = () => {
    if (meetingData.start_date_time && meetingData.duration) {
      const startDateTime = new Date(meetingData.start_date_time); // Parse start_date_time to Date object
      const durationMinutes = parseInt(meetingData.duration); // Parse duration to number

      if (!isNaN(startDateTime.getTime()) && !isNaN(durationMinutes)) {
        const endDateTime = new Date(startDateTime.getTime() + durationMinutes * 60000);
        const emails = [meetingData.candidate_email, meetingData.intervier_email].filter(Boolean); // Filter out undefined/null values
        console.log(startDateTime)

        // Assuming scheduleMeeting takes an object with emails and start_time
        scheduleMeeting({ emails, start_time: formatDateWithOffset(startDateTime, '+05:30'), end_time: formatDateWithOffset(endDateTime, '+05:30') })
          .then((res) => {
            window.open(res.auth_url, '_blank');
            // alert("Meeting Scheduled Successfully");
          })
          .catch((error) => {
            console.error("Error scheduling meeting:", error);
          });

      } else {
        console.error('Invalid start_date_time or duration'); // Handle parsing errors
      }
    }
  };

  useEffect(() => {
    fetch_all_candidate().then((res) => {
      var tempArr: {
        name: string, college: string, email: string, phone_number: string, secondary: string, higersecondary: string, sem_avg: string, skill_set: string, result: string
      }[] = []
      res.data.map((test: { [key: string]: string }) => {
        var tempData: { name: string, college: string, email: string, phone_number: string, secondary: string, higersecondary: string, sem_avg: string, skill_set: string, result: string } = {
          name: test.name,
          college: test.college,
          email: test.email,
          phone_number: test.phone_number,
          secondary: test.tenth_marks,
          higersecondary: test.twelth_marks,
          sem_avg: test.all_average_marks,
          skill_set: test.skill_set,
          result: test.result
        }
        tempArr.push(tempData)
      })
      setStudentdata(tempArr)
    })
  }, [])

  return (
    <div className={`card ${className}`}>

      {/* Scheduling Preview */}
      <div className="modal fade" id="kt_modal_schedule" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-450px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Schedule Technical Meeting</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <form>
                  <label>Intervier Email</label>
                  <select
                    value={meetingData?.intervier_email}
                    onChange={(e) => setMeetingData({ ...meetingData, intervier_email: e.target.value })}
                    className="form-control"
                  >
                    <option value="">None</option>
                    <option value="vishal.y@thirdeyedata.ai">Vishal Yadav</option>
                    <option value="nihal@thirdeyedata.ai">Nihal Kumar</option>
                    <option value="shweta@thirdeyedata.ai">Shweta Shinde</option>
                    <option value="debodey158@gmail.com">Debo Dey</option>
                  </select>
                  <br />
                  <label htmlFor="datetime">Select Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datetime"
                    name="datetime"
                    className="form-control"
                    onChange={(e) => {
                      setMeetingData({ ...meetingData, start_date_time: e.target.value });
                    }}
                  />
                  <br />
                  <label>Duration</label>
                  <select
                    value={meetingData?.duration}
                    onChange={(e) => setMeetingData({ ...meetingData, duration: e.target.value })}
                    className="form-control"
                  >
                    <option value="">None</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                  </select>
                </form>
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-100 fs-5"
                onClick={handleScheduling}
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="timer" className="fs-3" />
                Confirm Schedule
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <Link to='/screeningtest/create' className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            New Test
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-150px rounded-start'>Name</th>
                <th className='min-w-250px'>College name</th>
                <th className='min-w-125px'>Email Id</th>
                <th className='min-w-125px'>Phone no.</th>
                <th className='min-w-100px'>10th marks</th>
                <th className='min-w-100px'>12th marks</th>
                <th className='min-w-100px'>All sem avg</th>
                {/* <th className='min-w-200px'>Skill set</th> */}
                <th className='min-w-100px'>Result</th>
                <th className='min-w-100px rounded-end text-end px-2'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {studentdata.map((test, i) => (

                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        {/* <img
                        src={toAbsoluteUrl('/media/stock/600x400/img-26.jpg')}
                        className=''
                        alt=''
                      /> */}
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        {/* <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Sant Extreanet Solution
                      </a> */}
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {test.name}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    $2,790
                  </a> */}
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.college}</span>
                  </td>
                  <td>
                    {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    $2,790
                  </a> */}
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.email}</span>
                  </td>
                  <td>
                    {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    $520
                  </a> */}
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.phone_number}</span>
                  </td>
                  <td>
                    {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    Bradly Beal
                  </a> */}
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.secondary}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.higersecondary}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.secondary}</span>
                  </td>
                  {/* <td>
                  <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>{test.skill_set}</span>
                </td> */}
                  <td>
                    <span className='badge badge-light-primary fs-7 fw-semibold'>{test.result}</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-primary btn-sm me-1'
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_schedule"
                      onClick={(e) => {
                        setMeetingData({ ...meetingData, candidate_email: test.email })
                      }}
                    >
                      <KTIcon iconName='call' className='fs-3' />Schedule Meet
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget11 };
