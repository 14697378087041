/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { KTIcon } from '../../../../_metronic/helpers';
import { downloadAttachmentsZoho, fetchCandidates, generateMcqFromResume, scheduleMeeting } from "../../../api";
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues
} from "./settings/SettingsModel";

type Props = {
  className: string
  title: string
}

const options: string[] = ["5", "10", "20"];
const quotientsWithRemainderZero: number[] = [1, 2, 4, 5]

const profileDetailsSchema = Yup.object().shape({
  candidateEmail: Yup.string().required("Email is required"),
  totalQuestion: Yup.number().required("total Question is required"),
  totalSet: Yup.number().required("total Set is required"),
  datetime: Yup.string().required("Test Date and Time is required"),
  expiry: Yup.string().required("Expiry Date and Time is required"),
});

const TablesWidget11: React.FC<Props> = ({ className, title }) => {

  const [candidates, setCandidates] = useState<{ name: String, record_id: String }[]>([])
  const [resumeFile, setResumeFile] = useState<String>()
  const [downloding, setDownloding] = useState<boolean>(true)
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [meetingData, setMeetingData] = useState<{
    candidate_email?: string,
    intervier_email?: string,
    start_date_time?: string,
    duration?: string
  }>({})

  const navigate = useNavigate()

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      // const updatedData = Object.assign(data, values);
      // console.log(updatedData);
      const result = await generateMcqFromResume({ ...values, filePath: `/var/www/cs/build/media/candidate_resume/${resumeFile}` });
      if (result.data) {
        alert("Test Successfully Scheduled")
        setResult(result.data);
        navigate('/scheduletest/view')
      }
      setLoading(false)
    }
  });

  function formatDateWithOffset(dateString: any, offset: any) {
    // Create a Date object from the input date string
    const date = new Date(dateString);

    // Function to pad numbers with leading zeroes
    const pad = (num: number) => num.toString().padStart(2, '0');

    // Get the components
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Format as ISO 8601 with the provided offset
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offset}`;

    return formattedDate;
  }


  const downloadResune = (id: String) => {
    setDownloding(false)
    downloadAttachmentsZoho({ candidate_id: id }).then((res) => {
      setResumeFile(res.results[0].filename)
      setDownloding(true)
    })
  }

  const handleScheduling = () => {
    if (meetingData.start_date_time && meetingData.duration) {
      const startDateTime = new Date(meetingData.start_date_time); // Parse start_date_time to Date object
      const durationMinutes = parseInt(meetingData.duration); // Parse duration to number

      if (!isNaN(startDateTime.getTime()) && !isNaN(durationMinutes)) {
        const endDateTime = new Date(startDateTime.getTime() + durationMinutes * 60000);
        const emails = [meetingData.candidate_email, meetingData.intervier_email].filter(Boolean); // Filter out undefined/null values
        console.log(startDateTime)

        // Assuming scheduleMeeting takes an object with emails and start_time
        scheduleMeeting({ emails, start_time: formatDateWithOffset(startDateTime, '+05:30'), end_time: formatDateWithOffset(endDateTime, '+05:30') })
          .then((res) => {
            window.open(res.auth_url, '_blank');
            // alert("Meeting Scheduled Successfully");
          })
          .catch((error) => {
            console.error("Error scheduling meeting:", error);
          });

      } else {
        console.error('Invalid start_date_time or duration'); // Handle parsing errors
      }
    }
  };


  useEffect(() => {
    fetchCandidates().then((res) => {
      setCandidates(res.candidates)
    })
  }, [])

  return (
    <div className={`card ${className}`}>

      {/* Resume Preview Modal */}
      <div
        className="modal fade"
        id="kt_modal_resume_preview"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-700px">
          <div className="modal-content">
            <div className="modal-header">
              <h3>Candidate Resume</h3>
              <div
                className="btn btn-sm btn-icon"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y">
              {
                resumeFile &&
                <iframe
                  src={`/media/candidate_resume/${resumeFile}`}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                ></iframe>
              }
            </div>
            {/* end::Modal body */}
          </div>
        </div>
      </div>

      {/* Resume Scheduling Modal */}
      <div
        className="modal fade"
        id="kt_modal_resume_scheduling"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-700px">
          <div className="modal-content">
            <div className="modal-header">
              <h3>Schedule Test</h3>
              <div
                className="btn btn-sm btn-icon"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y">
              <form>
                <div id="kt_account_profile_details" className="collapse show">
                  <form noValidate className="form">
                    <div className="card-body border-top p-9">
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Candidate Email</span>
                        </label>

                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter candidate email"
                            {...formik.getFieldProps("candidateEmail")}
                          />
                          {formik.touched.candidateEmail && formik.errors.candidateEmail && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.candidateEmail}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Total Question</span>
                        </label>

                        <div className="col-lg-8 fv-row">
                          <select className="form-select form-select-solid form-select-lg fw-bold"
                            {...formik.getFieldProps("totalQuestion")}
                          >
                            <option selected>Select Total Number of Question</option>
                            {options.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {formik.touched.totalQuestion && formik.errors.totalQuestion && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.totalQuestion}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Total Set</span>
                        </label>

                        <div className="col-lg-8 fv-row">
                          <select
                            className="form-select form-select-solid form-select-lg fw-bold"
                            {...formik.getFieldProps("totalSet")}
                          >
                            <option selected>Select Total Number of Set</option>
                            {quotientsWithRemainderZero.map((quotients, index) => (
                              <option key={index} value={quotients}>
                                {quotients}
                              </option>
                            ))}
                          </select>
                          {formik.touched.totalSet && formik.errors.totalSet && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.totalSet}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Datetime</span>
                        </label>

                        <div className="col-lg-8 fv-row">
                          <input
                            type="datetime-local"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter date and time"
                            {...formik.getFieldProps("datetime")}
                          />
                          {formik.touched.datetime && formik.errors.datetime && (
                            <div className="fv-plugins-message-container">
                              {/* <div className="fv-help-block">{formik.errors.datetime}</div> */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          <span className="required">Expiry</span>
                        </label>

                        <div className="col-lg-8 fv-row">
                          <input
                            type="datetime-local"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter date and time"
                            {...formik.getFieldProps("expiry")}
                          />
                          {formik.touched.expiry && formik.errors.expiry && (
                            <div className="fv-plugins-message-container">
                              {/* <div className="fv-help-block">{formik.errors.datetime}</div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      <button
                        type="submit"
                        onClick={(e) => { e.preventDefault(); formik.handleSubmit() }}
                        className="btn btn-success"
                        disabled={loading}
                      >
                        {!loading && "Schedule Test"}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </form>
            </div>
            {/* end::Modal body */}
          </div>
        </div>
      </div>

      {/* Scheduling Preview */}
      <div className="modal fade" id="kt_modal_schedule" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-450px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Schedule Technical Meeting</h2>
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div>
                <form>
                  <label>Intervier Email</label>
                  <select
                    value={meetingData?.intervier_email}
                    onChange={(e) => setMeetingData({ ...meetingData, intervier_email: e.target.value })}
                    className="form-control"
                  >
                    <option value="">None</option>
                    <option value="vishal.y@thirdeyedata.ai">Vishal Yadav</option>
                    <option value="nihal@thirdeyedata.ai">Nihal Kumar</option>
                    <option value="shweta@thirdeyedata.ai">Shweta Shinde</option>
                    <option value="debodey158@gmail.com">Debo Dey</option>
                  </select>
                  <br />
                  <label htmlFor="datetime">Select Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datetime"
                    name="datetime"
                    className="form-control"
                    onChange={(e) => {
                      setMeetingData({ ...meetingData, start_date_time: e.target.value });
                    }}
                  />
                  <br />
                  <label>Duration</label>
                  <select
                    value={meetingData?.duration}
                    onChange={(e) => setMeetingData({ ...meetingData, duration: e.target.value })}
                    className="form-control"
                  >
                    <option value="">None</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                  </select>
                </form>
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary me-3 my-3 w-100 fs-5"
                onClick={handleScheduling}
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="timer" className="fs-3" />
                Confirm Schedule
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
        <div className='card-toolbar'>
          <Link to='' className='btn btn-sm btn-light-primary'>
            Fetch All Resumes
          </Link>
        </div>
      </div>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-325px rounded-start'>Candidate Name</th>
                <th className='ps-4 min-w-125px'>Source</th>
                <th className='pe-4 min-w-325px text-end rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {candidates.length > 0 && candidates.map((candidate, i) => (
                <tr>
                  <td className='ps-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {candidate.name}
                        </span>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='badge badge-light-primary fs-7 fw-semibold'>
                      Zoho Recruit
                    </span>
                  </td>
                  <td className='pe-4 min-w-325px text-end rounded-end'>
                    <button className='btn btn-sm btn-primary mx-2'
                      type='button'
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_resume_preview"
                      onClick={(e) => {
                        downloadResune(candidate.record_id)
                      }}
                    >
                      View Resume
                    </button>
                    <button className='btn btn-sm btn-success mx-2'
                      type='button'
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_resume_scheduling"
                    >
                      Schedule Screening Test
                    </button>
                    <button className='btn btn-sm btn-dark mx-2'
                      type='button'
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_schedule"
                    >
                      Schedule Interview
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget11 };

