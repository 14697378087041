/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ListsWidget1
} from "../../../_metronic/partials/widgets";
import { ProfileHeader } from "../../modules/profile/ProfileHeader";
import { useAuth } from "../../modules/auth";
import { getAllSuperAdminStats } from "../../api";
import { Link } from "react-router-dom";

type Props = {
  countUniversity: number;
  countAssociatedLicences: number;
  countLicences: number;
};

const DashboardPage = ({
  countUniversity,
  countAssociatedLicences,
  countLicences
}: Props) => (
  <div>
    
  </div>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const [superAdminStats, setSuperAdminStats] = useState({
    countUniversity: 0,
    countLicences: 0,
    countAssociatedLicences: 0
  });
  useEffect(() => {
    // getAllSuperAdminStats().then((res) => {
    //   setSuperAdminStats(res);
    // });
  }, []);
  return (
    <div className="container-xxl">
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-12">
          <div className="card-header border-0 pt-6">
            <div className="card-title">
              {/* <div className="d-flex align-items-center justify-content-between position-relative my-1">
                <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-1000px ps-14"
                  placeholder="Search"
                  value=""
                />
                <div
                  className="d-flex justify-content-end my-1"
                  data-kt-user-table-toolbar="base"
                >
                  <button
                    type="button"
                    className="btn btn-light-primary me-3 w-200px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i className="ki-duotone ki-filter fs-2">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    Filter
                  </button>
                  <div
                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true"
                  >
                    <div className="px-7 py-5">
                      <div className="fs-5 text-dark fw-bolder">
                        Filter Options
                      </div>
                    </div>
                    <div className="separator border-gray-200"></div>
                    <div className="px-7 py-5" data-kt-user-table-filter="form">
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">Type:</label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                        >
                          <option value=""></option>
                          <option value="Administrator">Administrator</option>
                          <option value="Analyst">Analyst</option>
                          <option value="Developer">Developer</option>
                          <option value="Support">Support</option>
                          <option value="Trial">Trial</option>
                        </select>
                      </div>
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Last login:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="two-step"
                          data-hide-search="true"
                        >
                          <option value=""></option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="20 mins ago">20 mins ago</option>
                          <option value="5 hours ago">5 hours ago</option>
                          <option value="2 days ago">2 days ago</option>
                        </select>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="reset"
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn btn-success fw-bold px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="filter"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <ListsWidget1 className="card-xl-stretch mb-xl-8" />
        </div>
      </div>
    </div>
  );
};

export { DashboardWrapper };
