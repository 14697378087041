import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Overview } from './components/Overview'
import { Settings } from './components/settings/Settings'
import { ResumeSchedulingHeader } from './ResumeSchedulingHeader'
import { Logout, AuthPage, useAuth } from "../auth";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Screening Tests',
    path: '/screeningtest/view',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ResumeSchedulingPage: React.FC = () => {

  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route
        element={
          <>
            {/* <ResumeSchedulingHeader /> */}
            <Outlet />
          </>
        }
      >
        <Route
          path='view'
          element={
            <>
              {/* <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle> */}
              <Overview />
            </>
          }
        />
        <Route
          path='create'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Create Test</PageTitle>
              <Settings />
            </>
          }
        />
        <Route index element={<Navigate to='/resumescheduling/view' />} />
      </Route>
    </Routes>
  )
}

export default ResumeSchedulingPage
