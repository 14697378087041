import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { Mixed } from "../modules/widgets/components/Mixed";
import SinghiDocumentProcessing from "../pages/layout-builder/SinghiDocumentProcessing";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import CandidateResultPage from "../pages/layout-builder/CandidateResultPage";
import CandidateDashboard from "../pages/dashboard/CandidateDashboard";
import ResumeSchedulingPage from "../modules/resumescheduling/ResumeSchedulingPage";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const CandidatePage = lazy(() => import("../modules/candidate/CandidatePage"));
  const QuestionSetPage = lazy(() => import("../modules/questionset/QuestionSetPage"));
  const ScreeningTestPage = lazy(() => import("../modules/screeningtests/ScreeningTestPage"));
  const ScheduleTestPage = lazy(() => import("../modules/scheduletest/ScheduleTestPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  const { currentUser } = useAuth();

  console.log(currentUser)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={(<Navigate to="/dashboard" />)} />
        <Route
          path="candidate/*"
          element={
            <SuspensedView>
              <CandidatePage />
            </SuspensedView>
          }
        />
        <Route
          path="dashboard"
          element={
            currentUser?.usertype == "admin" ? <DashboardWrapper /> : <CandidateDashboard />
          }
        />
        {
          currentUser?.usertype == "admin" &&
          <>
            <Route
              path="candidateresult"
              element={<CandidateResultPage />}
            />
            <Route
              path="questionset/*"
              element={
                <SuspensedView>
                  <QuestionSetPage />
                </SuspensedView>
              }
            />
            <Route
              path="screeningtest/*"
              element={
                <SuspensedView>
                  <ScreeningTestPage />
                </SuspensedView>
              }
            />
            <Route
              path="scheduletest/*"
              element={
                <SuspensedView>
                  <ScheduleTestPage />
                </SuspensedView>
              }
            />
            <Route
              path="resumescheduling/*"
              element={
                <SuspensedView>
                  <ResumeSchedulingPage />
                </SuspensedView>
              }
            />
          </>
        }

        <Route
          path="documentprocessing"
          element={<SinghiDocumentProcessing />}
        />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="candidate/*"
          element={
            <SuspensedView>
              <CandidatePage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
