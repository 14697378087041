export interface IProfileDetails {
  totalQuestion: number
  totalSet: number
  candidateEmail: string
  datetime: Date
  expiry: Date
}

export interface QuestionSetDetails {
  id: string
  setname: string
  questionCount: number
}


export const profileDetailsInitValues: IProfileDetails = {
  totalQuestion: 0,
  totalSet: 0,
  candidateEmail: "",
  datetime: new Date(),
  expiry: new Date(),
}
