import axios from 'axios';
// export const baseUri = '${API_URL}/app';

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "https://singhico.optira.ai:8000"
// const API_URL = "http://localhost:8000"

export const websiteCategory = async (query) => {
  const { data } = await axios.post(`${API_URL}/web_cat/`, query);
  return data;
};

export const textSummary = async (query) => {
  const { data } = await axios.post(`${API_URL}/text_summary/`, query);
  return data;
};

export const documentSummary = async (query) => {
  const { data } = await axios.post(`${API_URL}/document_summary/`, query);
  return data;
};

export const documentTextExtraction = async (query) => {
  const { data } = await axios.post(`${API_URL}/document_text_extraction/`, query);
  return data;
};

export const tagextraction = async (query) => {
  const { data } = await axios.post(`${API_URL}/extract_keyword/`, query);
  return data;
};

export const fileTextExtraction = async (query) => {
  const { data } = await axios.post(`${API_URL}/document_file_to_text_extr/`, query);
  return data;
};

export const filesByUser = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_folder_name/`, query);
  return data;
};

export const processFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/process_doc/`, query);
  return data;
};

export const processMultipleFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/schedule_process_singhi/`, query);
  return data;
};

export const cronjob_data = async (query) => {
  const { data } = await axios.post(`${API_URL}/cronjob_data/`, query);
  return data;
};

export const sqlDataCreate = async (query) => {
  const { data } = await axios.post(`${API_URL}/HAL_Data_Create/`, query);
  return data;
};

export const processCotractData = async (query) => {
  const { data } = await axios.post(`${API_URL}/process_contract_data/`, query);
  return data;
}

export const uploadFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/upload_multiple_doc_singhi/`, query);
  return data;
};

export const storeTrainingData = async (query) => {
  const { data } = await axios.post(`${API_URL}/StoreTrainingData/`, query);
  return data;
};

export const getClientFileList = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_all_clients_file_list_singhi/`, query);
  return data;
};

export const deleteFiles = async (query) => {
  const { data } = await axios.post(`${API_URL}/DeleteFiles/`, query);
  return data;
};

export const aiChat = async (query) => {
  const { data } = await axios.post(`${API_URL}/customChatGpt/`, query);
  return data;
};

export const customSearch = async (query) => {
  const { data } = await axios.post(`${API_URL}/customSearch/`, query);
  return data;
};

export const allCategories = async (query) => {
  const { data } = await axios.post(`${API_URL}/all_categories/`, query);
  return data;
};

export const categorySearchByFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/category_search_by_file/`, query);
  return data;
};

export const fileSearchByCategory = async (query) => {
  const { data } = await axios.post(`${API_URL}/file_search_by_category/`, query);
  return data;
};

export const readCsv = async (query) => {
  const { data } = await axios.post(`${API_URL}/read_csv/`, query);
  return data;
};

export const downloadGdriveFile = async (query) => {
  const { data } = await axios.post(`${API_URL}/downloadGdriveFile/`, query);
  return data;
};

export const getClientListSinghi = async (client) => {
  const { data } = await axios.post(`${API_URL}/get_client_list_singhi/`, client);
  return data;
};

export const getAllSuperAdminStats = async () => {
  const { data } = await axios.get(`${API_URL}/getAllSuperAdminStats`);
  return data;
};

export const getAllAssociatedStudents = async ({id}) => {
  const { data } = await axios.get(`${API_URL}/getAllAssociatedStudents?universityId=${id}`);
  return data;
};

export const getAllUniversityStats = async ({id}) => {
  const { data } = await axios.get(`${API_URL}/getAllUniversityStats?universityId=${id}`);
  return data;
};

export const getAllUnassociatedStudents = async ({id}) => {
  const { data } = await axios.get(`${API_URL}/getAllUnassociatedStudents?universityId=${id}`);
  return data;
};

export const getAllUnassociatedLicence = async ({id}) => {
  const { data } = await axios.get(`${API_URL}/getAllUnassociatedLicence?universityId=${id}`);
  return data;
};

export const countClient = async (pefix) => {
  const { data } = await axios.post(`${API_URL}/count_all_clients_singhi/`, pefix);
  return data;
};

export const getAllLicences = async ({id}) => {
  const { data } = await axios.get(`${API_URL}/getAllLicences?universityId=${id}`);
  return data;
};

export const licenceAssociation = async (query) => {
  const { data } = await axios.patch(`${API_URL}/licenceAssociation`, query);
  return data;
}

export const register = async (admin) => {
  const { data } = await axios.post(`${API_URL}/register_user_singhi/`, admin);
  return data;
};

export const login = async (admin) => {
  const { data } = await axios.post(`${API_URL}/login_singhi/`, admin);
  return data;
};

export const forgotPassword = async (admin) => {
  const { data } = await axios.post(`${API_URL}/forgot_password_singhi/`, admin);
  return data;
};

export const resetPassword = async (admin) => {
  const { data } = await axios.post(`${API_URL}/reset_password_singhi/`, admin);
  return data;
};


// export const getUserByToken = async (token) => {
//   const { data } = await axios.post(`${API_URL}/getUserByToken_singhi/`, token);
//   return data;
// };

export const studentRegister = async (student) => {
  const { data } = await axios.post(`${API_URL}/studentRegister`, student);
  return data;
};

export const licenceRegister = async (licence) => {
  const { data } = await axios.post(`${API_URL}/licenceRegister`, licence);
  return data;
};

export const searchStudent = async (student) => {
  const { data } = await axios.post(`${API_URL}/searchStudent`, student);
  return data;
};


// candidate screening

export const countDocuments = async (query) => {
  const { data } = await axios.post(`${API_URL}/count_documents_view/`, query);
  return data;
};


export const saveQuestionsSet = async (payload) => {
  const { data } = await axios.post(`${API_URL}/save_questions_set/`, payload);
  return data;
};

export const generateMcqFromPdf = async (payload) => {
  const { data } = await axios.post(`${API_URL}/generate_mcq_from_pdf/`, payload);
  return data;
};

export const fetchQuestionsSet = async (query) => {
  const { data } = await axios.post(`${API_URL}/fetch_questions_set/`, query);
  return data;
};

export const saveScreeningTest = async (payload) => {
  const { data } = await axios.post(`${API_URL}/save_screening_test/`, payload);
  return data;
};

export const fetchScreeningTest = async (query) => {
  const { data } = await axios.post(`${API_URL}/fetch_screening_test/`, query);
  return data;
};

export const fetchCandidates = async (query) => {
  const { data } = await axios.get(`${API_URL}/fetch_candidates/`, query);
  return data;
};

export const downloadAttachmentsZoho = async (query) => {
  const { data } = await axios.post(`${API_URL}/download_attachments_from_zoho/`, query);
  return data;
};

export const fetchScreeningTestById = async (query) => {
  const { data } = await axios.post(`${API_URL}/fetch_screening_test_by_id/`, query);
  return data;
};

export const fetchScheduledTest = async (query) => {
  const { data } = await axios.get(`${API_URL}/fetch_scheduled_test/`, query);
  return data;
};

export const filterScheduledTest = async (query) => {
  const { data } = await axios.post(`${API_URL}/filter_scheduling/`, query);
  return data;
};

export const filterScheduledTestByEmail = async (query) => {
  const { data } = await axios.post(`${API_URL}/fetch_scheduled_test_by_email/`, query);
  return data;
};

export const saveSchedulingTest = async (payload) => {
  const { data } = await axios.post(`${API_URL}/save_scheduling_test/`, payload);
  return data;
};

export const generateMcqFromResume = async (payload) => {
  const { data } = await axios.post(`${API_URL}/generate_mcq_from_resume/`, payload);
  return data;
};

export const saveSchedulingTestCSV = async (payload) => {
  const { data } = await axios.post(`${API_URL}/insert_data/`, payload);
  return data;
};

export const saveCandidateDetails = async (payload) => {
  const { data } = await axios.post(`${API_URL}/update_user/`, payload);
  return data;
};

export const fetchSchedulingTest = async (query) => {
  const { data } = await axios.get(`${API_URL}/fetch_scheduled_test/`, query);
  return data;
};

export const getRandomQuestions = async (query) => {
  const { data } = await axios.post(`${API_URL}/get_random_questions/`, query);
  return data;
};

export const checkQuesAns = async (payload) => {
  const { data } = await axios.post(`${API_URL}/check_que_ans/`, payload);
  return data;
};

export const fetch_all_candidate = async (query) => {
  const { data } = await axios.get(`${API_URL}/fetch_all_candidate/`, query);
  return data;
};

export const fetchCandidate = async (query) => {
  const { data } = await axios.post(`${API_URL}/fetch_candidate/`, query);
  return data;
};

export const scheduleMeeting = async (query) => {
  const { data } = await axios.post(`${API_URL}/initiate_oauth2/`, query);
  return data;
};