/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../helpers'
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  test_name?: string
  datetime?: string
  status?: string
  expiry?: string
  result?: string
  testId?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  test_name,
  datetime,
  status,
  expiry,
  result,
  testId
}) => {


  const targetDatetime = datetime ? new Date(datetime) : null;
  const expiryDatetime = expiry ? new Date(expiry) : null;


  const dt = new Date();
  const shouldShowStartButton = targetDatetime !== null && expiryDatetime !== null && dt < expiryDatetime;

  const navigate = useNavigate()
  const handleStartTest = (e: any) => {
    e.preventDefault()
    if (datetime && expiry && testId) {
      const targetDatetime = new Date(datetime);
      const expiryDatetime = new Date(expiry);
      const dt = new Date();

      if (dt.getTime() > targetDatetime.getTime() && dt.getTime() < expiryDatetime.getTime()) {
        alert("dt is greater than the target datetime.");
        console.log(testId)
        // navgate to test/:id
        // navigate(`/candidate/test/${testid}`)

        navigate("/candidate/navigate")
      } else if (dt.getTime() < targetDatetime.getTime()) {
        alert("The test has not started yet.");
      } else {
        // alert("dt is equal to the target datetime.");
      }
    }
  }
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      {/* Test Modal */}
      <div
        className="modal fade"
        id="kt_modal_test"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h3>Are you sure to start the test ?</h3>
                    <h4 className="text-muted fade-text">Note:If you navigate to test screen, you will not be able to back to home screen</h4>
                  </div>
                </div>
              </div>
              <div
                className="btn btn-sm btn-icon"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div id="alertDiv"></div>
              <div className="d-flex gap-2">
                <Link to={`/candidate/test/${testId}`}>
                  <button className='btn btn-sm btn-success' data-bs-dismiss="modal">Start Test</button>
                </Link>
                <Link to={`/dashboard`}>
                  <button className='btn btn-sm btn-success' data-bs-dismiss="modal">Cancel</button>
                </Link>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
        </div>
      </div>

      {/* Result Modal */}
      <div
        className="modal fade"
        id="kt_modal_result"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">{test_name} Result</h2>
              <div
                className="btn btn-sm btn-icon"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div id="alertDiv"></div>
              <h2>Result: {result}</h2>
            </div>
            {/* end::Modal body */}
          </div>
        </div>
      </div>

      <div className='card-body'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />
        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>
        <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        {!shouldShowStartButton || status == "Completed" ? (
          <button
            className='btn btn-success my-4'
            type='button'
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_result"
          >
            Result
          </button>
        ) : (
          <button
            className='btn btn-success my-4'
            type='button'
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_test"
          >
            Start Test
          </button>
        )}

        {/* if greater than expiry show result button */}

      </div>
    </a>
  )
}
export { StatisticsWidget5 }
